import * as ACTION_TYPES from './action_types';

export function scholarformtextchange(data, error) {
  return {
    type: ACTION_TYPES.SCHOLARFORMTEXTCHANGE,
    target: data.target,
    field: data.target.name,
    value: data.target.value,
    error: error || null,
  };
}

export function scholarcheckboxchange(data, error) {
  return {
    type: ACTION_TYPES.SCHOLARCHECKBOXCHANGE,
    target: data.target,
    field: data.target.name,
    value: data.target.checked,
    error: error || null,
  };
}

export function scholarradiobuttonchange(data, error) {
  return {
    type: ACTION_TYPES.SCHOLARRADIOBUTTONCHANGE,
    currentTarget: data,
    error: error || null,
  };
}

export function scholarfilechange(data, index, error) {
  return {
    type: ACTION_TYPES.SCHOLARFILECHANGE,
    index,
    uploadedFile: data,
    error: error || null,
  };
}

export function checkifemptytranscipts(uploadedFile, index, error) {
  return {
    type: ACTION_TYPES.CHECKIFEMPTYTRANSCRIPTS,
    index,
    uploadedFile,
    error: error || null,
  };
}

export function scholaracademicinputschange(data, index, error) {
  return {
    type: ACTION_TYPES.SCHOLARACADEMICINPUTSCHANGE,
    index,
    event: data,
    error: error || null,
  };
}

export function checkifemptyacademicinputs(name, value, index, error) {
  return {
    type: ACTION_TYPES.CHECKIFEMPTYACADEMICINPUTS,
    index,
    name,
    value,
    error: error || null,
  };
}

export function scholaraddacademicinputs() {
  return {
    type: ACTION_TYPES.SCHOLARADDACADEMICINPUTS,
  };
}

export function scholarremoveacademicinputs(index) {
  return {
    type: ACTION_TYPES.SCHOLARREMOVEACADEMICINPUTS,
    index,
  };
}

export function scholaraddtranscript() {
  return {
    type: ACTION_TYPES.SCHOLARADDTRANSCRIPT,
  };
}

export function scholarremovetranscript(index) {
  return {
    type: ACTION_TYPES.SCHOLARREMOVETRANSCRIPT,
    index,
  };
}

export function academicinfocheckchange(status) {
  return {
    type: ACTION_TYPES.ACADEMICINFOCHANGE,
    status,
  };
}

export function academictranscriptcheckchange(status) {
  return {
    type: ACTION_TYPES.ACADEMICTRANSCRIPTCHANGE,
    status,
  };
}

export function setcountrycode(value){
  return{
    type: ACTION_TYPES.SETCOUNTRYCODE,
    value
  }
}
