import React, { useState, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import { Row, setConfiguration } from 'react-grid-system';
import {
  FaHome,
  FaNewspaper,
  FaShoePrints,
  FaShapes,
  FaObjectUngroup,
  FaGlobeAfrica,
  FaFistRaised,
} from 'react-icons/fa';
import logo from '../../static/logo.svg';
import * as styles from './Header.module.scss';
import './Header.scss';

// DECLARING VARIABLES
const links = [
  { name: 'About', path: '/about' },
  { name: 'Features', path: '/features' },
  { name: 'Articles', path: '/articles' },
  { name: 'Projects', path: '/projects' },
  { name: 'People', path: '/people' },
  { name: 'Join us', path: '/join' },
];

const icons = [FaShoePrints, FaShapes, FaNewspaper, FaObjectUngroup, FaGlobeAfrica, FaFistRaised];

setConfiguration({ defaultScreenClass: 'sm', containerWidths: [540, 740, 960, 1276, 1540] });

const Header = () => {
  // DECLARING STATES AND VARIABLES AND REFS
  const [menuOpen, setMenuOpen] = useState(false);
  const indexHeader = useRef();
  const menuUl = useRef();

  function CloseMenu() {
    setMenuOpen((menuState) => !menuState);
  }

  // close mobile menu after navigation to new page
  // useEffect(() => {
  //   const navLinks = menuUl.current.querySelectorAll('li');

  //   navLinks.forEach((link) => {
  //     link.addEventListener('click', CloseMenu);
  //   });

  //   return navLinks.forEach((link) => {
  //     link.removeEventListener('click', CloseMenu);
  //   });
  // }, []);

  // handle hamburger click
  function onClickHamburger() {
    setMenuOpen((menuState) => !menuState);
  }
  // checkOnClick ends

  // handle hamburger click
  function onClickMobileMenu(e) {
    e.preventDefault();
    const linkTo = e.target.closest('a').getAttribute('href');
    CloseMenu();
    navigate(linkTo);
  }
  // checkOnClick ends

  const headerStyle = {
    width: '100vw',
    maxWidth: '100%',
  };

  return (
    <header className='mainMenu' id='mainMenu' style={headerStyle}>
      <div className='mainMenuWrapper'>
        {/* Black mobile menu bar */}
        <Row justify='end' align='center' style={{ padding: '9px 0' }} className='blackMenuBar d-sm-none'>
          {/* <small>Reshaping the African Perspective!</small> */}
          <Link
            to='/donate'
            className={`${styles.donate} me-2 me-lg-5`}
            aria-label='Opens donate page'
          >
            Donate
          </Link>
        </Row>

        {/* Main Menu Holder */}

        <Row justify='space-between' align='center' style={{ padding: '9px 0' }} className='mainMenuHolder'>
          <Link to='/' className={styles.logoHolder} id='navLogo'>
            <img src={logo} className={styles.logo} alt="The Africa I Know's logo" />
          </Link>

          {/* DESKTOP NONRESPONSIVE MENU */}
          <nav className={`${styles.nav} desktopNav`} ref={indexHeader}>
            <ul>
              <li className={`${styles.linkHolder}`}>
                <Link to='/' activeClassName='active'>
                  <button type='button'>Home</button>
                </Link>
              </li>

              {links.map((link) => {
                const { name, path } = link;

                return (
                  <li key={name} className={styles.linkHolder}>
                    <Link to={path} activeClassName='active' partiallyActive>
                      <button type='button'>{name}</button>
                    </Link>
                  </li>
                );
              })}
            </ul>

            <Link to='/donate' className={styles.donate} aria-label='Opens donate page'>
              Donate
            </Link>
          </nav>

          {/* MOBILE RESPONSIVE MENU */}
          <nav
            className={`${styles.nav} ${menuOpen ? 'showMenu' : ''} navResponsive`}
            id='navResponsive'
            role='navigation'
          >
            {/* HAMBURGER CLOSE MOBILE MENU BUTTON */}

            <div
              className={`${styles.hamburgerClose} ${menuOpen ? 'showMenu' : ''} ms-auto`}
              id='hamburgerClose'
            >
              <div className='hamburgerWrapper'>
                <button
                  type='button'
                  className='hamburger'
                  aria-haspopup='true'
                  aria-expanded='true'
                  aria-label='Close the menu'
                  onClick={onClickHamburger}
                >
                  <div className='h-bar' />
                  <div className='mid-bar'>
                    <span>CLOSE</span>
                    <div className='mini-h-bar' />
                  </div>
                  <div className='h-bar' />
                </button>
              </div>
            </div>
            {/* BLACK BACKGROUND ON MOBILE MENU DROPDOWN */}
            <div
              className={`${menuOpen ? 'showToggle' : ''} toggled-background`}
              id='toggledBackground'
              onClick={onClickHamburger}
            />
            <ul ref={menuUl}>
              <li className={`${styles.linkHolder}`}>
                <a href='/' onClick={onClickMobileMenu}>
                  <div className={styles.menuIconHolder}>
                    <FaHome style={{ color: '#125c00' }} />
                  </div>
                  <span className='textHolder'>
                    <span>Home</span>
                  </span>
                </a>
              </li>

              {links.map((link, index) => {
                const { name, path } = link;
                const Icon = icons[index] ? icons[index] : FaHome;

                return (
                  <li key={name} className={styles.linkHolder}>
                    <a href={path} onClick={onClickMobileMenu}>
                      <div className={styles.menuIconHolder}>
                        <Icon style={{ color: '#125c00' }} />
                      </div>
                      <span className='textHolder'>
                        <span>{name}</span>
                      </span>
                    </a>
                  </li>
                );
              })}

              <div className='navSpriteBox'>
                <div className='sprite1' />
                <div className='sprite2' />
                <div className='sprite3' />
                <div className='sprite4' />
              </div>
            </ul>
          </nav>

          {/* Donate Button md and lg */}
          <Link
            to='/donate'
            className={`${styles.donate} sm_md_lg_donate me-3 me-lg-5 d-none d-sm-inline-block`}
            aria-label='Opens donate page'
          >
            Donate
          </Link>
          {/* HAMBURGER OPEN MOBILE MENU BUTTON */}
          <div className={`${styles.hamburgerOpen}`} id='hamburgerOpen'>
            <div className='hamburgerWrapper'>
              <button
                type='button'
                className='hamburger'
                aria-haspopup='true'
                aria-expanded='false'
                aria-label='Open the menu'
                onClick={onClickHamburger}
              >
                <div className='h-bar' />
                <div className='mid-bar'>
                  <span>MENU</span>
                  <div className='mini-h-bar' />
                </div>
                <div className='h-bar' />
              </button>
            </div>
          </div>
        </Row>
      </div>
    </header>
  );
};

export default Header;
