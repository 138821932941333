export const SCHOLARFORMTEXTCHANGE = 'SCHOLARFORMTEXTCHANGE';
export const SCHOLARCHECKBOXCHANGE = 'SCHOLARCHECKBOXCHANGE';
export const SCHOLARRADIOBUTTONCHANGE = 'SCHOLARRADIOBUTTONCHANGE';
export const SCHOLARFILECHANGE = 'SCHOLARFILECHANGE';
export const SCHOLARACADEMICINPUTSCHANGE = 'SCHOLARACADEMICINPUTSCHANGE';
export const SCHOLARADDACADEMICINPUTS = 'SCHOLARADDACADEMICINPUTS';
export const SCHOLARREMOVEACADEMICINPUTS = 'SCHOLARREMOVEACADEMICINPUTS';
export const SCHOLARADDTRANSCRIPT = 'SCHOLARADDTRANSCRIPT';
export const SCHOLARREMOVETRANSCRIPT = 'SCHOLARREMOVETRANSCRIPT';
export const ACADEMICINFOCHANGE = 'ACADEMICINFOCHANGE';
export const STUDYPLANCHANGE = 'STUDYPLANCHANGE';
export const ACADEMICTRANSCRIPTCHANGE = 'ACADEMICTRANSCRIPTCHANGE';
export const CHECKIFEMPTYACADEMICINPUTS = 'CHECKIFEMPTYACADEMICINPUTS';
export const CHECKIFEMPTYTRANSCRIPTS = 'CHECKIFEMPTYTRANSCRIPTS';
export const SETCOUNTRYCODE = 'SETCOUNTRYCODE';
