import React from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-grid-system';
import {
  // FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';

import logo from '../../static/logofooter.svg';
import * as styles from './Footer.module.scss';

const socials = [
  {
    path: 'https://twitter.com/theafricaiknow_',
    icon: <FaTwitter size={24} />
  },
  {
    path: 'https://www.facebook.com/africaiknow/',
    icon: <FaFacebookF size={24} />
  },
  {
    path: 'https://www.linkedin.com/company/theafricaiknow/',
    icon: <FaLinkedinIn size={24} />
  },
  {
    path: 'https://www.youtube.com/channel/UC1-D_Zgj1pyPF1qLop3fASw',
    icon: <FaYoutube size={24} />,
  },
  // { path: '/', icon: <FaEnvelope size={24} /> },
  {
    path: 'https://www.instagram.com/theafricaiknow_/',
    icon: <FaInstagram size={24} />
  },
  {
    path: 'https://www.tiktok.com/@theafricaiknow_',
    icon: <FaTiktok size={24} />,
  },
];

const Footer = () => (
  <footer className='section-padding'>
    <Container>
      <Row align='center' direction='column'>
        <img src={logo} className={styles.logo} alt='Logo Footer' />

        <Col lg={8} className={`${styles.socials_holder}`}>
          <div className={styles.socials}>
            {socials.map(({ path, icon }, idx) => (
              <Link
                key={idx}
                className={`${styles.social} mb-xs-0 mb-3`}
                to={path}
                style={{
                  '--social-button-color': `var(--color-white)`,
                  '--social-button-color-hover': `var(--color-dark-200)`,
                  '--social-button-size': `52px`,
                  '--social-button-margin': `18px`,
                }}
                target='_blank'
                rel='noreferrer'
              >
                {icon}
              </Link>
            ))}
          </div>

          <p className={styles.copyright}>
            {new Date().getFullYear()} © <Link to='/'>The Africa I Know</Link> • All rights
            reserved.
          </p>

          <p>The Africa I Know is a 501(c)(3) nonprofit organization and a registered trademark.</p>

          <p className={styles.licenseNav}>
            <Link to='/privacypolicy'>Privacy statement</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Link to='/licenseandattributions'>Licence & attributions</Link>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
