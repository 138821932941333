exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ambassador-js": () => import("./../../../src/pages/ambassador.js" /* webpackChunkName: "component---src-pages-ambassador-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-cancelled-js": () => import("./../../../src/pages/cancelled.js" /* webpackChunkName: "component---src-pages-cancelled-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-education-fund-js": () => import("./../../../src/pages/education-fund.js" /* webpackChunkName: "component---src-pages-education-fund-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-licenseandattributions-js": () => import("./../../../src/pages/licenseandattributions.js" /* webpackChunkName: "component---src-pages-licenseandattributions-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-single-article-js": () => import("./../../../src/pages/single-article.js" /* webpackChunkName: "component---src-pages-single-article-js" */),
  "component---src-pages-single-featured-js": () => import("./../../../src/pages/single-featured.js" /* webpackChunkName: "component---src-pages-single-featured-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-taik-54-js": () => import("./../../../src/pages/taik54.js" /* webpackChunkName: "component---src-pages-taik-54-js" */),
  "component---src-pages-taikscholars-js": () => import("./../../../src/pages/taikscholars.js" /* webpackChunkName: "component---src-pages-taikscholars-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-templates-single-article-prod-js": () => import("./../../../src/templates/single-article-prod.js" /* webpackChunkName: "component---src-templates-single-article-prod-js" */),
  "component---src-templates-single-feature-prod-js": () => import("./../../../src/templates/single-feature-prod.js" /* webpackChunkName: "component---src-templates-single-feature-prod-js" */)
}

