// extracted by mini-css-extract-plugin
export var donate = "Header-module--donate--8qfsa";
export var hamburgerClose = "Header-module--hamburgerClose--fmiT0";
export var hamburgerOpen = "Header-module--hamburgerOpen--9fxfj";
export var linkHolder = "Header-module--linkHolder--ltm-C";
export var logHolder = "Header-module--logHolder--Ev0ub";
export var logo = "Header-module--logo--oAWCX";
export var logoHolder = "Header-module--logoHolder--s0sho";
export var menuIconHolder = "Header-module--menuIconHolder--q9q80";
export var nav = "Header-module--nav--fkPik";
export var navResponsive = "Header-module--navResponsive--Jm5o5";