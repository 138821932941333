import 'typeface-montserrat';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@styles/index.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import React from 'react';
import Wrapper from '@layouts/Wrapper';
import MainContext from './src/context/GeneralContext';

export const wrapRootElement = ({ element }) => <MainContext>{element}</MainContext>;

// Wrap all pages in Header and Footer and that does not Re-Render
export const wrapPageElement = ({ element, props }) => <Wrapper {...props}>{element}</Wrapper>;
