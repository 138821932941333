import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function Wrapper({ children }) {
  return (
    <>
      <Header />
      <main id='index-main'>{children}</main>
      <Footer />
    </>
  );
}
