import React, { useState, createContext, useMemo, useReducer } from 'react';
import * as ACTIONS from '../store/actions/actions';
import * as ScholarReducer from '../store/reducers/scholarReducer';

// const checkWindow = typeof window !== 'undefined';

// const checkStorage = typeof Storage !== 'undefined';

export const GeneralContext = createContext(null);

export default function MainContext({ children }) {
  // DECLARING VARIABLES AND STATES

  // for popup videos
  const [homeVideoModal, setHomeVideoModal] = useState(false);
  const [scholarsVideoModal, setScholarsVideoModal] = useState(false);
  const [taik54VideoModal, setTaik54VideoModal] = useState(false);
  const [educationFundVideoModal, setEducationFundVideoModal] = useState(false);

  // for popup donation modal
  const [donateModal, setDonateModal] = useState(null);

  // for graphql articles
  const [articles, setArticles] = useState([]);

  // for graphql features
  const [features, setFeatures] = useState([]);

  // for graphql features
  const [quotes, setQuotes] = useState([]);

  // Reducer for SCHOLAR FORMS
  const [stateScholarReducer, dispatchScholarReducer] = useReducer(
    ScholarReducer.ScholarReducer,
    ScholarReducer.initialState,
  );

  // functions for handling changes to REDUCER state - dispatch
  const handlescholarformtextchange = (data, error) => {
    dispatchScholarReducer(ACTIONS.scholarformtextchange(data, error));
  };

  const handlescholarcheckboxchange = (data, error) => {
    dispatchScholarReducer(ACTIONS.scholarcheckboxchange(data, error));
  };

  const handlescholarradiobuttonchange = (data, error) => {
    dispatchScholarReducer(ACTIONS.scholarradiobuttonchange(data, error));
  };

  const handlescholarfilechange = (data, index, error) => {
    dispatchScholarReducer(ACTIONS.scholarfilechange(data, index, error));
  };

  const handlecheckifemptytranscipts = (uploadedFile, index, error) => {
    dispatchScholarReducer(ACTIONS.checkifemptytranscipts(uploadedFile, index, error));
  };

  const handlescholaracademicinputschange = (data, index, error) => {
    dispatchScholarReducer(ACTIONS.scholaracademicinputschange(data, index, error));
  };

  const handlescholaraddacademicinputs = () => {
    dispatchScholarReducer(ACTIONS.scholaraddacademicinputs());
  };

  const handlescholarremoveacademicinputs = (index) => {
    dispatchScholarReducer(ACTIONS.scholarremoveacademicinputs(index));
  };

  const handlecheckifemptyacademicinputs = (name, value, index, error) => {
    dispatchScholarReducer(ACTIONS.checkifemptyacademicinputs(name, value, index, error));
  };

  const handlescholaraddtranscript = () => {
    dispatchScholarReducer(ACTIONS.scholaraddtranscript());
  };

  const handlescholarremovetranscript = (index) => {
    dispatchScholarReducer(ACTIONS.scholarremovetranscript(index));
  };

  const handleacademictranscriptcheckchange = (status) => {
    dispatchScholarReducer(ACTIONS.academictranscriptcheckchange(status));
  };

  const handleacademicinfocheckchange = (status) => {
    dispatchScholarReducer(ACTIONS.academicinfocheckchange(status));
  };

  const handlesetcountrycode = (value) => {
    dispatchScholarReducer(ACTIONS.setcountrycode(value));
  };

  // Assignining values for context
  const generalValues = useMemo(
    () => ({
      homeVideoModal,
      setHomeVideoModal,
      scholarsVideoModal,
      setScholarsVideoModal,
      taik54VideoModal,
      setTaik54VideoModal,
      educationFundVideoModal,
      setEducationFundVideoModal,
      donateModal,
      setDonateModal,
      articles,
      setArticles,
      features,
      setFeatures,
      quotes,
      setQuotes,
      scholarFirstName: stateScholarReducer.firstName,
      scholarLastName: stateScholarReducer.lastName,
      scholarBirthdate: stateScholarReducer.birthdate,
      scholarResidence: stateScholarReducer.residence,
      scholarNationality: stateScholarReducer.nationality,
      scholarEmail: stateScholarReducer.email,
      scholarPhone: stateScholarReducer.phone,
      scholarFieldofstudy: stateScholarReducer.fieldofstudy,
      scholarStudyplanexpanded: stateScholarReducer.studyplanexpanded,
      scholarHerebyCertify: stateScholarReducer.herebyCertify,
      scholarPrivacyPolicy: stateScholarReducer.privacyPolicy,
      scholarIsPenultimate: stateScholarReducer.isPenultimate,
      scholarAcademicInputs: stateScholarReducer.academicInputs,
      scholarTranscriptInputs: stateScholarReducer.transcriptInputs,
      academicInfoCheck: stateScholarReducer.academicInfoCheck,
      academicTranscriptCheck: stateScholarReducer.academicTranscriptCheck,
      countrycode: stateScholarReducer.countrycode,
      scholarformtextchange: (data, error) => handlescholarformtextchange(data, error),
      scholarcheckboxchange: (data, error) => handlescholarcheckboxchange(data, error),
      scholarradiobuttonchange: (data, error) => handlescholarradiobuttonchange(data, error),
      scholarfilechange: (data, index, error) => handlescholarfilechange(data, index, error),
      scholaracademicinputschange: (data, index, error) =>
        handlescholaracademicinputschange(data, index, error),
      scholaraddacademicinputs: () => handlescholaraddacademicinputs(),
      scholarremoveacademicinputs: (index) => handlescholarremoveacademicinputs(index),
      scholaraddtranscript: () => handlescholaraddtranscript(),
      scholarremovetranscript: (index) => handlescholarremovetranscript(index),
      academicinfocheckchange: (status) => handleacademicinfocheckchange(status),
      checkifemptyacademicinputs: (name, value, index, error) =>
        handlecheckifemptyacademicinputs(name, value, index, error),
      checkifemptytranscipts: (uploadedFile, index, error) =>
        handlecheckifemptytranscipts(uploadedFile, index, error),
      academictranscriptcheckchange: (status) => handleacademictranscriptcheckchange(status),
      setcontrycode: (value) => handlesetcountrycode(value),
    }),
    [
      donateModal,
      homeVideoModal,
      scholarsVideoModal,
      taik54VideoModal,
      educationFundVideoModal,
      articles,
      features,
      quotes,
      stateScholarReducer.firstName,
      stateScholarReducer.lastName,
      stateScholarReducer.birthdate,
      stateScholarReducer.residence,
      stateScholarReducer.nationality,
      stateScholarReducer.email,
      stateScholarReducer.phone,
      stateScholarReducer.fieldofstudy,
      stateScholarReducer.studyplanexpanded,
      stateScholarReducer.herebyCertify,
      stateScholarReducer.privacyPolicy,
      stateScholarReducer.isPenultimate,
      stateScholarReducer.academicInputs,
      stateScholarReducer.transcriptInputs,
      stateScholarReducer.academicInfoCheck,
      stateScholarReducer.academicTranscriptCheck,
      stateScholarReducer.countrycode,
    ],
  );

  return <GeneralContext.Provider value={generalValues}>{children}</GeneralContext.Provider>;
}
