import DOMPurify from 'dompurify';
import * as ACTION_TYPES from '../actions/action_types';

export const initialState = {
  firstName: { value: '', error: null },
  lastName: { value: '', error: null },
  birthdate: { value: '', error: null },
  residence: { value: '', error: null },
  nationality: { value: '', error: null },
  email: { value: '', error: null },
  phone: { value: '', error: null },
  isPenultimate: { value: 'Yes', error: null },
  academicInputs: [
    {
      schoolName: { value: '', error: null },
      subject: { value: '', error: null },
      grade: { value: '', error: null },
      index: 0,
    },
  ],
  transcriptInputs: [{ file: null, error: null, index: 0 }],
  fieldofstudy: { value: '', error: null },
  studyplanexpanded: { value: '', error: null },
  privacyPolicy: { value: false, error: null },
  herebyCertify: { value: false, error: null },
  academicInfoCheck: false,
  academicTranscriptCheck: false,
  countrycode: '',
};

export const ScholarReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SCHOLARFORMTEXTCHANGE: {
      if (action.field === 'birthdate') {
        const birthdateInput = action.target;
        birthdateInput.closest('input').style.color = '#061901';
      }
      let currentField;

      if (action.field === 'firstName') {
        currentField = 'firstName';
      }
      if (action.field === 'lastName') {
        currentField = 'lastName';
      }
      if (action.field === 'birthdate') {
        currentField = 'birthdate';
      }
      if (action.field === 'residence') {
        currentField = 'residence';
      }
      if (action.field === 'nationality') {
        currentField = 'nationality';
      }
      if (action.field === 'email') {
        currentField = 'email';
      }
      if (action.field === 'phone') {
        currentField = 'phone';
      }
      if (action.field === 'fieldofstudy') {
        currentField = 'fieldofstudy';
      }
      if (action.field === 'studyplanexpanded') {
        currentField = 'studyplanexpanded';
      }

      return {
        ...state,
        [currentField]: {
          value: DOMPurify.sanitize(action.value),
          error: action.error || null,
        },
      };
    }
    case ACTION_TYPES.SCHOLARCHECKBOXCHANGE: {
      let currentField;

      if (action.field === 'herebyCertify') {
        currentField = 'herebyCertify';
      }

      if (action.field === 'privacyPolicy') {
        currentField = 'privacyPolicy';
      }

      return {
        ...state,
        [currentField]: {
          value: action.value,
          error: action.error || null,
        },
      };
    }
    case ACTION_TYPES.SCHOLARRADIOBUTTONCHANGE: {
      return {
        ...state,
        isPenultimate: {
          value: action.currentTarget.value,
          error: action.error || null,
        },
      };
    }

    // CHANGES VALUE FOR GRADES,SUBJECT,SCHOOLNAME IN ACADEMIC INFO SCHOLARS FORM
    case ACTION_TYPES.SCHOLARACADEMICINPUTSCHANGE: {
      const academicList = [...state.academicInputs];
      academicList[action.index][action.event.target.name] = {
        value: DOMPurify.sanitize(action.event.target.value),
        error: action.error || null,
      };
      academicList[action.index].index = action.index;

      return {
        ...state,
        academicInputs: academicList,
      };
    }
    // ERROR CHECK AND CHANGES VALUE FOR GRADES,SUBJECT,SCHOOLNAME IN ACADEMIC INFO SCHOLARS FORM
    case ACTION_TYPES.CHECKIFEMPTYACADEMICINPUTS: {
      const academicList = [...state.academicInputs];

      academicList[action.index][action.name] = {
        value: action.value || '',
        error: action.error || null,
      };
      academicList[action.index].index = action.index;

      return {
        ...state,
        academicInputs: academicList,
      };
    }
    // CHANGES VALUE FOR TRANSCRIPT IN ACADEMIC INFO SCHOLARS FORM
    case ACTION_TYPES.SCHOLARFILECHANGE: {
      const transcriptList = [...state.transcriptInputs];
      transcriptList[action.index] = {
        file: action.uploadedFile,
        error: null,
        index: action.index,
      };

      return {
        ...state,
        transcriptInputs: transcriptList,
      };
    }
    // ERROR CHECK AND CHANGES VALUE FOR TRANSCRIPT IN ACADEMIC INFO SCHOLARS FORM
    case ACTION_TYPES.CHECKIFEMPTYTRANSCRIPTS: {
      const newTranscriptList = [...state.transcriptInputs];
      newTranscriptList[action.index] = {
        file: action.uploadedFile,
        error: action.error || null,
        index: action.index,
      };

      return {
        ...state,
        transcriptInputs: newTranscriptList,
      };
    }

    case ACTION_TYPES.SCHOLARADDACADEMICINPUTS: {
      const newAcademicInputList = [
        ...state.academicInputs,
        {
          schoolName: { value: '', error: null },
          subject: { value: '', error: null },
          grade: { value: '', error: null },
          index: '',
        },
      ];
      return {
        ...state,
        academicInputs: newAcademicInputList,
      };
    }
    case ACTION_TYPES.SCHOLARREMOVEACADEMICINPUTS: {
      const removeAcademicInputList = [...state.academicInputs];
      removeAcademicInputList.splice(action.index, 1);
      return {
        ...state,
        academicInputs: removeAcademicInputList,
      };
    }
    case ACTION_TYPES.SCHOLARADDTRANSCRIPT: {
      const newTranscriptList = [...state.transcriptInputs, { file: null, error: null, index: '' }];

      return {
        ...state,
        transcriptInputs: newTranscriptList,
      };
    }

    case ACTION_TYPES.SCHOLARREMOVETRANSCRIPT: {
      const removeTranscriptList = [...state.transcriptInputs];
      removeTranscriptList.splice(action.index, 1);
      return {
        ...state,
        transcriptInputs: removeTranscriptList,
      };
    }
    case ACTION_TYPES.ACADEMICINFOCHANGE: {
      return {
        ...state,
        academicInfoCheck: action.status,
      };
    }

    case ACTION_TYPES.ACADEMICTRANSCRIPTCHANGE: {
      return {
        ...state,
        academicTranscriptCheck: action.status,
      };
    }

    // FOR SETTING COUNTRY CODE ON SCHOLAR FORM
    case ACTION_TYPES.SETCOUNTRYCODE: {
      return {
        ...state,
        countrycode: action.value,
      };
    }

    default: {
      return state;
    }
  }
};
